/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/shared/Hero'
import Cases from 'components/shared/Cases'
import Projects from '../components/shared/Projects'

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...ProjectsPageFrag
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, acf, yoast_meta: yoast },
  },
}) => {
  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{
          url:
            acf.banner.image.localFile &&
            acf.banner.image.localFile.childImageSharp.openGraph.src,
        }}
      />

      <Hero className="mb-lg-3" content={acf.banner} />

      <Projects className="pt-5" />
    </Layout>
  )
}

export default PageTemplate
